<template>
  <div v-loading="loading">
    <div ref="screen" class="input">
      <!--    顶部选项按钮 开始-->
      <el-row>
        <el-button type="primary" @click="getReceivables" size="mini" v-if="authe.Hotel_Finance_HouseAccountManage_AccountsReceivable">收款账户</el-button>&nbsp;&nbsp;
          <el-dialog title="收款账户" :visible.sync="receivables.dialogReceivables" v-loading="loading" :close-on-click-modal="false" @close="dialogReceivablesClose" width="90%">
            <el-row>
              <el-button type="primary" 
              @click="addAccount" 
              size="mini" 
              v-if="authe.Hotel_Finance_HouseAccountManage_AddAccount">
              添加账户
              </el-button>&nbsp;&nbsp;
                <el-dialog 
                :title="receivables.account.title" 
                :visible.sync="receivables.account.dialogAccount" 
                @close="closeDialogAccount" 
                v-loading="loading" 
                append-to-body 
                :close-on-click-modal="false" width="600px">
                  <el-form inline 
                  :label-width="screenLabelWidth" 
                  ref="account"
                  :rules="rules" 
                  :model="receivables.account.params">
                    <el-row v-if="receivables.account.state">
                      <el-form-item label="账户状态" >
                        <el-select v-model="receivables.account.params.faState" size="mini" class="screenInput">
                          <el-option label="正常" value="正常"></el-option>
                          <el-option label="注销" value="注销"></el-option>
                          <el-option label="异常" value="异常"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="账户类型" prop="faPaymentType">
                        <el-select v-model="receivables.account.params.faPaymentType" filterable clearable size="mini" class="screenInput">
                          <el-option v-for="(value,i) in accountType" :key="i" :label="value.variable" :value="value.variable"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="优先级">
                        <el-select v-model="receivables.account.params.faRank" size="mini" class="screenInput">
                          <el-option label="1" value="1"></el-option>
                          <el-option label="2" value="2"></el-option>
                          <el-option label="3" value="3"></el-option>
                          <el-option label="4" value="4"></el-option>
                          <el-option label="5" value="5"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="账户名称" prop="faUserName">
                        <el-input v-model="receivables.account.params.faUserName" size="mini" style="width: 335px"></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="账户号码" prop="faAccount">
                        <el-input v-model="receivables.account.params.faAccount" size="mini" style="width: 335px"></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="账户归属" prop="faBelonging">
                        <el-input v-model="receivables.account.params.faBelonging" size="mini" style="width: 335px"></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="期初金额">
                        <el-input v-model="receivables.account.params.faTheInitialAmount" :disabled="receivables.account.modifyState" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                      <el-form-item v-if="receivables.account.state">
                        <el-button @click="modityState" size="mini">修改期初金额</el-button>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="账户余额" v-if="receivables.account.state">
                        <el-input v-model="receivables.account.params.faTheBalanceOf" disabled size="mini" style="width: 335px"></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="账户描述">
                        <el-input v-model="receivables.account.params.faDescribe" type="textarea" rows="2" size="mini" style="width: 335px"></el-input>
                      </el-form-item>
                    </el-row>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="receivables.account.dialogAccount = false">取 消</el-button>
                    <el-button type="primary" @click="modifyAccountSave" v-if="receivables.account.state">保 存</el-button>
                    <el-button type="primary" @click="addAccountSave" v-else>保 存</el-button>
                  </div>
                </el-dialog>
              <el-button type="primary" @click="modifyAccount" size="mini" v-if="authe.Hotel_Finance_HouseAccountManage_ModifyAccount">修改账户</el-button>
              <el-button type="primary" @click="deployment" size="mini" v-if="authe.Hotel_Finance_HouseAccountManage_FundAllocation">资金调配</el-button>&nbsp;&nbsp;
                <el-dialog title="资金调配"  :visible.sync="receivables.deployment.dialogDeployment"  @close="closeDialogDeployment" v-loading="loading" append-to-body :close-on-click-modal="false" width="600px">
                  <el-form inline :label-width="screenLabelWidth">
                    <el-row>
                      <el-col :span="12" style="padding-right:10px">
                        <el-divider content-position="left">转出账户</el-divider>
                          <el-form-item label="账户类型">
                            <el-input v-model="receivables.deployment.params.faPaymentType" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="账户名称">
                            <el-input v-model="receivables.deployment.params.faUserName" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="账户号码">
                            <el-input v-model="receivables.deployment.params.faAccount" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="账户归属">
                            <el-input v-model="receivables.deployment.params.faBelonging" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="账户余额">
                            <el-input v-model="receivables.deployment.params.faTheBalanceOf" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="金额变化">
                            <el-input v-model="receivables.deployment.params.moneyChange" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="12" style="padding-left:10px">
                        <el-divider content-position="left">转入账户</el-divider>
                          <el-form-item label="账户类型">
                            <el-select v-model="receivables.deployment.params2.faPaymentType" @change="getBankAccount2" size="mini" class="screenInput">
                              <el-option v-for="(value,i) in accountType" :key="i" :label="value.variable" :value="value.variable"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="账户名称">
                            <el-select v-model="receivables.deployment.params2.faUserName" @change="getBankAccountInfor" size="mini" class="screenInput">
                              <el-option v-for="(value,i) in receivables.deployment.accountName" :key="i" :label="value.faUserName" :value="value.faId"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="账户号码">
                            <el-input v-model="receivables.deployment.params2.faAccount" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="账户归属">
                            <el-input v-model="receivables.deployment.params2.faBelonging" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="账户余额">
                            <el-input v-model="receivables.deployment.params2.faTheBalanceOf" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="金额变化">
                            <el-input v-model="receivables.deployment.params2.moneyChange" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row style="text-align: center">
                      <el-form-item label="调配金额">
                        <el-input v-model="receivables.deployment.moneyChange" @change="MoneyChange" type="text" oninput = "value=value.replace(/[^\d]/g,'')" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                    </el-row>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="receivables.deployment.dialogDeployment = false">取 消</el-button>
                    <el-button type="primary" @click="deploymentSave">保 存</el-button>
                  </div>
                </el-dialog>
              <el-button type="primary" @click="getReceivablesMoney(1)" size="mini">重新统计账户余额</el-button>&nbsp;&nbsp;
            </el-row>
            <el-row>
              <el-form inline :label-width="screenLabelWidth">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="登记时间">
                      <el-date-picker
                        v-model="receivables.time"
                        @change="receivablesDateChange"
                        :unlink-panels="true"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        filterable
                        clearable
                        size="mini"
                        :picker-options="receivables.pickerOptions"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="16">
                    <el-form-item label="账户类型">
                      <el-select v-model="receivables.params.faPaymentType" @change="receivablesSearchChange" filterable clearable size="mini" class="screenInput">
                        <el-option v-for="(value,i) in accountType" :key="i" :label="value.variable" :value="value.variable"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="账户状态">
                      <el-select v-model="receivables.params.faState" @change="receivablesSearchChange" filterable clearable size="mini" class="screenInput">
                        <el-option label="正常" value="正常"></el-option>
                        <el-option label="注销" value="注销"></el-option>
                        <el-option label="异常" value="异常"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="优先级">
                      <el-select v-model="receivables.params.faRank" @change="receivablesSearchChange" filterable clearable size="mini" class="screenInput">
                        <el-option label="1" value="1"></el-option>
                        <el-option label="2" value="2"></el-option>
                        <el-option label="3" value="3"></el-option>
                        <el-option label="4" value="4"></el-option>
                        <el-option label="5" value="5"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="账户余额" label-width="100px">
                      <span style="color: blue;">{{receivables.receivablesMoney}} 元</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-row>
            <div style="text-align:center;">
              <el-table
              :data="receivables.dataList"
              highlight-current-row
              @current-change="receivablesTableCurrentChange"
              @row-dblclick="budgetDetails"
              @select-all="SelectionChange"
              @selection-change="SelectionChange"
              ref="multipleTable"
              height="288px"        
              :row-style="{height: '35px'}"
              style="width=100%;">
                <el-table-column prop="faPaymentType" label="账户类型" show-overflow-tooltip min-width="50px"></el-table-column>
                <el-table-column prop="faUserName" label="账户名称" show-overflow-tooltip min-width="50px"></el-table-column>
                <el-table-column prop="faBelonging" label="账户归属" show-overflow-tooltip min-width="50px"></el-table-column>
                <el-table-column prop="faAccount" label="账户号码" show-overflow-tooltip min-width="50px"></el-table-column>
                <el-table-column prop="faDescribe" label="账户描述" show-overflow-tooltip min-width="50px"></el-table-column>
                <el-table-column prop="faTheInitialAmount" label="期初金额" show-overflow-tooltip min-width="50px"></el-table-column>
                <el-table-column prop="faTheBalanceOf" label="账户余额" show-overflow-tooltip min-width="50px"></el-table-column>
                <el-table-column prop="financialSummary" label="收支统计" show-overflow-tooltip min-width="50px"></el-table-column>
                <el-table-column prop="faState" label="账户状态" show-overflow-tooltip min-width="50px"></el-table-column>
              </el-table>
              <el-pagination
                background layout="total, sizes, prev, pager, next, jumper"
                :page-count="receivables.pages"
                :page-size="receivables.params.limit"
                :total="receivables.total"
                :current-page="receivables.params.cursor"
                @size-change="receivablesSizeChange"
                @current-change="receivablesCurrentChange"
                :page-sizes="[5,10,20,40]">
              </el-pagination>
            </div>
            <el-dialog title="相关收支详情" :visible.sync="receivables.budgetDetails.dialogBudgetDetails" append-to-body  :close-on-click-modal="false" width="95%" @close="budgetDetailsClose">
              <el-form inline :label-width="screenLabelWidth">
                <el-row>
                  <el-form-item label="收支总额">
                    <span style="width: 100px;color: blue;">{{receivables.budgetDetails.amountDetail.summary}}</span> 元
                  </el-form-item>
                  <el-form-item label="总收入">
                    <span style="width: 100px;color: blue;">{{receivables.budgetDetails.amountDetail.income}}</span> 元
                  </el-form-item>
                  <el-form-item label="总支出">
                    <span style="width: 100px;color: blue;">{{receivables.budgetDetails.amountDetail.expenditure}}</span> 元
                  </el-form-item>
                  <el-form-item label="总冲账">
                    <span style="width: 100px;color: blue;">{{receivables.budgetDetails.amountDetail.strike}}</span> 元
                  </el-form-item>
                  <el-form-item label="周期">
                    <el-date-picker
                      v-model="receivables.budgetDetails.time"
                      @change="budgetDetailsDateChange"
                      :unlink-panels="true"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      filterable
                      clearable
                      size="mini"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="收支性质">
                    <el-select v-model="receivables.budgetDetails.params.jfNatureOfThe" @change="budgetDetailsSearchChange" filterable clearable size="mini" class="screenInput">
                      <el-option label="收入" value="收入"></el-option>
                      <el-option label="支出" value="支出"></el-option>
                      <el-option label="冲账" value="冲账"></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
              </el-form>
              <div style="text-align:center;">
                <el-table
                border
                :data="receivables.budgetDetails.dataList"
                v-loading="load"
                highlight-current-row
                @current-change="budgetDetailsTableCurrentChange"
                @row-dblclick="budgetDetailsDetails"
                height="288px"
                style="width=100%;">
                  <el-table-column prop="jfAuditState" label="财务状态" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfBillingDate" label="记账日期" width="95px"></el-table-column>
                  <el-table-column prop="jfTheOwnershipType" label="归属类型" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfBelongingToTheName" label="归属名称" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfClosedWay" label="账户类型" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfNatureOfThe" label="收支性质" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfBigType" label="收支分类" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfAccountingSpecies" label="收支种类" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfSumMoney" label="收支金额" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfNowBalance" label="账户余额" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfFinanNote" label="收支原因" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="handlersName" label="经手人" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfCheckInTime" label="登记日期" width="155px"></el-table-column>
                  <el-table-column prop="cashierPeopleName" label="记账人" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="reviewerName" label="审核人" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="reviewOneName" label="复核人" show-overflow-tooltip min-width="50px"></el-table-column>
                  <el-table-column prop="jfStrikeABalanceStatus" label="冲账状态" show-overflow-tooltip min-width="50px"></el-table-column>
                </el-table>
                <el-pagination
                  background layout="total, sizes, prev, pager, next, jumper"
                  :page-count="receivables.budgetDetails.pages"
                  :page-size="receivables.budgetDetails.params.limit"
                  :total="receivables.budgetDetails.total"
                  :current-page="receivables.budgetDetails.params.cursor"
                  @size-change="budgetDetailsSizeChange"
                  @current-change="budgetDetailsCurrentChange"
                  :page-sizes="[5,10,20,40]">
                </el-pagination>
              </div>
              <!--    收支详细弹窗 开始-->
              <el-dialog title="收支详细" 
              :visible.sync="receivables.budgetDetails.details.dialogBudgetDetailsDetails" 
              append-to-body 
              :close-on-click-modal="false"
              width="680px" 
              class="screen">
                <el-form inline :label-width="screenLabelWidth">
                  <el-row>
                    <el-form-item label="楼盘名称">
                      <el-input v-model="receivables.budgetDetails.details.params.jfAccountingWhy" disabled size="mini" style="width: 545px"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="财务状态">
                      <el-input v-model="receivables.budgetDetails.details.params.jfAuditState" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="冲账状态">
                      <el-input v-model="receivables.budgetDetails.details.params.jfStrikeABalanceStatus" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="经手人">
                      <el-input v-model="receivables.budgetDetails.details.params.handlersName" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="归属类型">
                      <el-input v-model="receivables.budgetDetails.details.params.jfTheOwnershipType" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="归属名称">
                      <el-input v-model="receivables.budgetDetails.details.params.jfBelongingToTheName" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="记账日期">
                      <el-input v-model="receivables.budgetDetails.details.params.jfBillingDate" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="账户类型">
                      <el-input v-model="receivables.budgetDetails.details.params.jfClosedWay" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="账户名称">
                      <el-input v-model="receivables.budgetDetails.details.params.faUserName" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="账户号码">
                      <el-input v-model="receivables.budgetDetails.details.params.faAccount" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="账户归属">
                      <el-input v-model="receivables.budgetDetails.details.params.faBelonging" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="收支性质">
                      <el-input v-model="receivables.budgetDetails.details.params.jfNatureOfThe" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="收支类别">
                      <el-input v-model="receivables.budgetDetails.details.params.jfBigType" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="收支种类">
                      <el-input v-model="receivables.budgetDetails.details.params.jfAccountingSpecies" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="收支金额">
                      <el-input v-model="receivables.budgetDetails.details.params.jfSumMoney" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="归属周期">
                      <el-input v-model="receivables.budgetDetails.details.params.jfStartCycle" disabled size="mini" style="width: 157px"></el-input>&nbsp;至
                      <el-input v-model="receivables.budgetDetails.details.params.jfEndCycle" disabled size="mini" style="width: 157px"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="票据编号">
                      <el-input v-model="receivables.budgetDetails.details.params.jfTicketNumber" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="流水号">
                      <el-input v-model="receivables.budgetDetails.details.params.jfFinancialCoding" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="收支原因">
                      <el-input v-model="receivables.budgetDetails.details.params.jfFinanNote" disabled size="mini" style="width: 545px"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="操作记录">
                      <el-input v-model="receivables.budgetDetails.details.params.jfOperationRecords" disabled size="mini" style="width: 545px"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="记账人">
                      <el-input v-model="receivables.budgetDetails.details.params.cashierPeopleName" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="审核人">
                      <el-input v-model="receivables.budgetDetails.details.params.reviewerName" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="复核人">
                      <el-input v-model="receivables.budgetDetails.details.params.reviewerOneName" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                </el-form>
              </el-dialog>
              <!--    收支详细弹窗 结束-->
              <div slot="footer" style="text-align: center">
                <el-button @click="receivables.budgetDetails.dialogBudgetDetails = false">取 消</el-button>
              </div>
            </el-dialog>
            <div slot="footer">
              <el-button @click="receivables.dialogReceivables = false">关 闭</el-button>
            </div>
          </el-dialog>
        <el-button type="primary" @click="isShowColumn.dialogShowColumn = true" @change="searchChange" clearable size="mini">显示/隐藏列</el-button>&nbsp;&nbsp;
          <!--    显示/隐藏列弹窗 开始-->
          <el-dialog title="选择列（勾选需要显示的列）" :visible.sync="isShowColumn.dialogShowColumn" v-loading="loading" :close-on-click-modal="false" width="600px">
            <el-checkbox :indeterminate="isShowColumn.isIndeterminate" v-model="isShowColumn.checkAll" @change="userAllChange">全选</el-checkbox>
            <div style="margin: 10px 0;"></div>
            <el-checkbox-group v-model="isShowColumn.checkedCities" @change="userCitiesChange">
              <el-checkbox v-for="(city,i) in isShowColumn.cities" :label="city.value" :key="i">{{city.label}}</el-checkbox>
            </el-checkbox-group>
            <div slot="footer">
              <el-button @click="isShowColumn.dialogShowColumn = false">取 消</el-button>
              <el-button type="primary" @click="showColumnSave">保 存</el-button>
            </div>
          </el-dialog>
          <!--    显示/隐藏列弹窗 开始-->
        <el-button type="primary" @click="advancedScreening" size="mini">高级筛选</el-button>
      </el-row>
      <!--    顶部选项按钮 结束-->
      <!--    筛选框 开始-->
      <el-row class="screen">
        <el-form inline :label-width="screenLabelWidth">
          <el-row>
            <el-form-item label="门店">
              <el-input v-model="data.params.hsAddCommunity" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="房间类型">
              <el-select v-model="data.params.hsRoomType" @change="searchChange" filterable clearable size="mini" class="screenInput">
                <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="房号">
              <el-input v-model="data.params.hsAddDoorplateno" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="审核状态">
              <el-select v-model="data.params.jfAuditState" @change="searchChange" filterable clearable size="mini" class="screenInput">
                <el-option label="未审核" value="未审核"></el-option>
                <el-option label="已审核" value="已审核"></el-option>
                <el-option label="已复核" value="已复核"></el-option>
                <el-option label="审核不通过" value="审核不通过"></el-option>
                <el-option label="复核不通过" value="复核不通过"></el-option>
                <el-option label="被冲账" value="被冲账"></el-option>
                <el-option label="无效" value="无效"></el-option>
              </el-select>
            </el-form-item>
            <span v-show="screenState">
              <el-form-item style="margin-left: 15px">
                <el-popover placement="top" width="156">
                  <el-button slot="reference" size="mini">
                    <span>排序方式</span>
                    <i class="el-icon-sort el-icon--right"></i>
                  </el-button>
                  <el-button-group>
                    <el-button :type="data.sortGp.ascBtn" @click="sortPopoverBtnGp('ascBtn','1')" size="mini" style="width: 78px;">正序<i class="el-icon-sort-down el-icon--right"></i></el-button>
                    <el-button :type="data.sortGp.descBtn" @click="sortPopoverBtnGp('descBtn','2')" size="mini" style="width: 78px;">倒序<i class="el-icon-sort-up el-icon--right"></i></el-button>
                  </el-button-group>
                    <el-button :type="data.sort.jfCheckInTime" @click="sortPopoverBtn('jfCheckInTime','1')" size="mini" style="width: 156px;margin: 5px 0 0 0">登记日期</el-button>
                    <el-button :type="data.sort.jfBillingDate" @click="sortPopoverBtn('jfBillingDate','2')" size="mini" style="width: 156px;margin: 5px 0 0 0">记账日期</el-button>
                </el-popover>
              </el-form-item>
            </span>
          </el-row>
          <el-row v-show="screenState">
            <el-form-item label="城区">
              <el-select v-model="data.params.hsAddDistrict" @change="searchChange" filterable clearable size="mini" class="screenInput">
                <el-option v-for="(value,i) in cityList" :key="i" :label="value.hdDistrict" :value="value.hdDistrict"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="冲账状态">
              <el-select v-model="data.params.jfStrikeABalanceStatus" @change="searchChange" filterable clearable size="mini" class="screenInput">
                <el-option label="正常" value="正常"></el-option>
                <el-option label="冲账" value="冲账"></el-option>
                <el-option label="被冲账" value="被冲账"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账户类型">
              <el-select v-model="data.params.jfClosedWay" @change="searchChange();getBankAccount()" filterable clearable size="mini" class="screenInput">
                <el-option v-for="(value,i) in accountType" :key="i" :label="value.variable" :value="value.variable"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账户名称">
              <el-select v-model="data.params.faId" @change="searchChange" filterable clearable size="mini" class="screenInput">
                <el-option v-for="(value,i) in data.accountName" :key="i" :label="value.faUserName" :value="value.faId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收支方式">
              <el-select v-model="data.params.jfPayType" @change="searchChange" filterable clearable size="mini" class="screenInput">
                <el-option label="现钞" value="现钞"></el-option>
                <el-option label="转账" value="转账"></el-option>
                <el-option label="刷卡" value="刷卡"></el-option>
                <el-option label="支票" value="支票"></el-option>
                <el-option label="在线支付" value="在线支付"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row v-show="screenState">
            <el-form-item label="票据编号">
              <el-input v-model="data.params.jfTicketNumber" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="登记时间">
              <el-date-picker
                v-model="data.time"
                @change="dateChange"
                :unlink-panels="true"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                filterable
                clearable
                size="mini"
              ></el-date-picker>
            </el-form-item>
          </el-row>
        </el-form>
      </el-row>
      <!--    筛选框 结束-->
    <!--    详细信息弹框 开始-->
      <el-dialog title="订单详情" :visible.sync="details.dialogDetails" @close="closeDialogDetails" :close-on-click-modal="false" width="890px" class="dialog">
        <el-form inline :label-width="screenLabelWidth">
          <el-divider content-position="left">收支归属</el-divider>
            <el-row>
              <el-form-item label="流水号">
                <el-input v-model="details.params.jfFinancialCoding" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="归属类型">
                <el-input v-model="details.params.jfTheOwnershipType" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="归属名称">
                <el-input v-model="details.params.jfBelongingToTheName" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="记账日期">
                <el-input v-model="details.params.jfBillingDate" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="费用归属">
                <el-input v-model="details.params.jfAccountingWhy" disabled size="mini" style="width: 665px"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="mini" @click="viewRoom" v-if="authe.Hotel_Finance_HouseAccountManage_ViewRooms">查看客房</el-button>
              </el-form-item>
            </el-row>
          <el-divider content-position="left">收支信息</el-divider>
            <el-row>
              <el-form-item label="账户类型">
                <el-input v-model="details.params.jfClosedWay" disabled size="mini" style="width: 185px"></el-input>
              </el-form-item>
              <el-form-item label="账户名称">
                <el-input v-model="details.params.faBelonging" disabled size="mini" style="width: 185px"></el-input>
              </el-form-item>
              <el-form-item label="账户号码">
                <el-input v-model="details.params.faAccount" disabled size="mini" style="width: 215px"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="收支性质">
                <el-input v-model="details.params.jfNatureOfThe" disabled size="mini" style="width: 185px"></el-input>
              </el-form-item>
              <el-form-item label="收支种类">
                <el-input v-model="details.params.jfAccountingSpecies" disabled size="mini" style="width: 185px"></el-input>
              </el-form-item>
              <el-form-item label="账户归属">
                <el-input v-model="details.params.faBelonging" disabled size="mini" style="width: 215px"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="收支金额">
                <el-input v-model="details.params.jfSumMoney" disabled size="mini" style="width: 185px"></el-input>
              </el-form-item>
              <el-form-item label="收支方式">
                <el-input v-model="details.params.jfPayType" disabled size="mini" style="width: 185px"></el-input>
              </el-form-item>
              <el-form-item label="归属周期">
                <el-input v-model="details.params.jfStartCycle" disabled size="mini" style="width: 96px"></el-input>&nbsp;到
                <el-input v-model="details.params.jfEndCycle" disabled size="mini" style="width: 96px"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="账户余额">
                <el-input v-model="details.params.jfNowBalance" disabled size="mini" style="width: 185px"></el-input>
              </el-form-item>
              <el-form-item label="票据编号">
                <el-input v-model="details.params.jfTicketNumber" disabled size="mini" style="width: 185px"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="收支原因">
                <el-input v-model="details.params.jfFinanNote" disabled type="textarea" rows="2" size="mini" style="width: 755px"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="操作记录">
                <el-input v-model="details.params.jfOperationRecords" disabled type="textarea" rows="2" size="mini" style="width: 755px"></el-input>
              </el-form-item>
            </el-row>
          <el-divider content-position="left">其他信息</el-divider>
            <el-row>
              <el-form-item label="财务状态">
                <el-input v-model="details.params.jfAuditState" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="冲账状态">
                <el-input v-model="details.params.jfStrikeABalanceStatus" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="经手人">
                <el-input v-model="details.dbClickRowData.handlersName" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="记账人">
                <el-input v-model="details.dbClickRowData.cashierPeopleName" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="审核人">
                <el-input v-model="details.dbClickRowData.reviewerName" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
              <el-form-item label="复核人">
                <el-input v-model="details.dbClickRowData.reviewOneName" disabled size="mini" class="screenInput"></el-input>
              </el-form-item>
            </el-row>
        </el-form>
        <!--    冲账弹窗 开始-->
        <el-dialog title="冲账操作" :visible.sync="details.rushAccounts.dialogRushAccounts" append-to-body :close-on-click-modal="false" width="460px">
          <el-form inline :label-width="screenLabelWidth">
              <el-form-item label="冲账原因">
                <el-input v-model="details.rushAccounts.rushAccountsNote" type="textarea" rows="3" size="mini" style="width: 335px"></el-input>
              </el-form-item>
          </el-form>
          <div slot="footer" style="text-align: center">
            <el-button @click="rushAccountsSave();">保 存</el-button>
            <el-button @click="details.rushAccounts.dialogRushAccounts = false">取 消</el-button>
          </div>
        </el-dialog>
        <!--    冲账弹窗 结束-->
        <!--    审核弹窗 开始-->
        <el-dialog title="审核操作" :visible.sync="details.examine.dialogExamine" append-to-body :close-on-click-modal="false" width="460px">
          <el-form inline :label-width="screenLabelWidth">
            <el-row>
              <el-form-item label="是否通过">
                <el-select v-model="details.examine.auditIsPass" size="mini" class="screenInput">
                  <el-option label="通过" value="通过"></el-option>
                  <el-option label="不通过" value="不通过"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="备注/原因">
                <el-input v-model="details.examine.auditNote" size="mini" style="width:335px"></el-input>
              </el-form-item>
            </el-row>
          </el-form>
          <div slot="footer" style="text-align: center">
            <el-button @click="examineSave">保 存</el-button>
            <el-button @click="details.examine.dialogExamine = false">取 消</el-button>
          </div>
        </el-dialog>
        <!--    审核弹窗 结束-->
        <div slot="footer" style="text-align: center">
          <el-button type="primary" v-if="details.params.jfNatureOfThe != '冲账' && details.params.jfAuditState != '被冲账' && authe.Hotel_Finance_HouseAccountManage_Offset" @click="details.rushAccounts.dialogRushAccounts = true" icon="el-icon-s-finance">冲 账</el-button>
          <el-button type="primary" v-if="details.params.jfAuditState == '已审核' && authe.Hotel_Finance_HouseAccountManage_RepeatReview" @click="details.examine.dialogExamine = true;" icon="el-icon-s-claim">复 核</el-button>
          <el-button type="primary" v-if="details.params.jfAuditState == '未审核' && authe.Hotel_Finance_HouseAccountManage_Review" @click="details.examine.dialogExamine = true;" icon="el-icon-s-claim">审 核</el-button>
          <el-button @click="changeRowData(false)">上一条</el-button>
          <el-button @click="details.dialogDetails = false">取 消</el-button>
          <el-button @click="changeRowData(true)">下一条</el-button>
        </div>
      </el-dialog>
    <!--    详细信息弹框 结束-->
    </div>
    <!--    数据列表 开始-->
    <div style="text-align:center;">
      <!--    表格显示列表 开始-->
      <el-table
      :data="dataList"
      v-loading="dataloading"
      ref="highLightRow"
      highlight-current-row
      :row-class-name="tableRowClassName"
      @current-change="currentChange"
      @row-dblclick="orderDetails"
      :height="tableHeight"
      style="width=100%;">
        <el-table-column label="财务状态" show-overflow-tooltip min-width="50px">
          <template slot-scope="scope">
            <span
              v-if="scope.row.jfAuditState == '未审核'"
              style="color: red;font-size: 14px;"
            >{{ scope.row.jfAuditState }}</span>
            <span
              v-else-if="scope.row.jfAuditState == '已审核'"
              style="color: green ;font-size: 14px"
            >{{ scope.row.jfAuditState }}</span>
            <span
              v-else-if="scope.row.jfAuditState == '已复核'"
              style="color: blue ;font-size: 14px"
            >{{ scope.row.jfAuditState }}</span>
            <span v-else >{{ scope.row.jfAuditState }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="jfBillingDate" label="记账日期" width="95px"></el-table-column>
        <el-table-column prop="jfTheOwnershipType" label="归属类型" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfBelongingToTheName" label="归属名称" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfClosedWay" label="账户类型" v-if="isShowColumn.showBinding.jfClosedWay" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfPayType" label="收支方式" v-if="isShowColumn.showBinding.jfPayType" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfNatureOfThe" label="收支性质" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfBigType" label="收支分类" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfAccountingSpecies" label="收支种类" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfSumMoney" label="收支金额" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfSettlementMethod" label="结算方式" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfCreditSituation" label="结清挂账" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfNowBalance" label="账户余额" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfFinanNote" label="收支原因" v-if="isShowColumn.showBinding.jfFinanNote" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="handlersName" label="经手人" v-if="isShowColumn.showBinding.handlersName" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="jfCheckInTime" label="登记日期" v-if="isShowColumn.showBinding.jfCheckInTime" width="155px"></el-table-column>
        <el-table-column prop="cashierPeopleName" label="记账人" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="reviewerName" label="审核人" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column prop="reviewOneName" label="复核人" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column label="冲账状态" show-overflow-tooltip min-width="50px">
          <template slot-scope="scope">
            <span
              v-if="scope.row.jfStrikeABalanceStatus == '被冲账'"
              style="color: red ;font-size: 14px"
            >{{ scope.row.jfStrikeABalanceStatus }}</span>
            <span
              v-else-if="scope.row.jfStrikeABalanceStatus == '冲账'"
              style="color: green ;font-size: 14px"
            >{{ scope.row.jfStrikeABalanceStatus }}</span>
            <span
              v-else-if="scope.row.jfStrikeABalanceStatus == '正常'"
              style="color: blue ;font-size: 14px"
            >{{ scope.row.jfStrikeABalanceStatus }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--    表格显示列表 结束-->

      <!--    分页 开始-->
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        :page-count="data.pages"
        :page-size="data.params.limit"
        :total="data.total"
        :current-page="data.params.cursor"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[5,10,20,40]"
      ></el-pagination>
      <!--    分页 结束-->
    </div>
    <!--    数据列表 结束-->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'guestAccount',
  data(){
    return{
      dataloading: false,
      loading: false,
      load: false,
      screenState: false,
      screenLabelWidth: '75px',
      tableHeight: window.innerHeight - 210,
      accountType: [], //账户类型数组
      accountName: [], //账户名称数据
      rules: {
        faPaymentType: [
          { required: true, message: '请选择账户类型', trigger: 'change' },
        ],
        faAccount: [
          { required: true, message: '请输入账户号码', trigger: 'blur' }
        ],
        faUserName: [
          { required: true, message: '请输入账户名称', trigger: 'blur' }
        ],
        faBelonging: [
          { required: true, message: '请输入账户归属', trigger: 'blur' }
        ],
      },
      receivables: {
        dialogReceivables: false,
        params: {
          startTime: "", // 记账日期 - 开始
          endTime: "", // 记账日期 - 结束
          faPaymentType: "", // 账户类型
          faRank: "", // 优先级
          faState: "正常", // 账户状态
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
        receivablesMoney: '', // 账户总余额
        time: '',
        dataList: [],
        rowData: {},
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        account: {
          dialogAccount: false,
          state: true,
          title: '',
          modifyState: true,
          params: {
            faAccount: "",
            faBelonging: "",
            faTheInitialAmount: 0,
            faDescribe: "",
            faPaymentType: "",
            faRank: 5,
            faTheBalanceOf: "",
            faUserName: "",
            faState: "", // 账户状态
          },
        },
        deployment: {
          dialogDeployment: false,
          destroy:false,
          params: {
            faAccount: "",
            faBelonging: "",
            faPaymentType: "",
            faTheBalanceOf: "",
            faUserName: "",
            moneyChange: 0
          },
          params2: {
            faAccount: "",
            faBelonging: "",
            faPaymentType: "",
            faTheBalanceOf: "",
            faUserName: "",
            moneyChange: 0
          },
          accountName: [],
          moneyChange: 0,
        },
        budgetDetails: {
          dialogBudgetDetails: false,
          params: {
            faId: "",
            jfNatureOfThe: '', //性质
            billDataEnd: '',
            billDataStart: '',
            limit: 10,//每页的条数
            cursor: 1,//初始页
          },
          input: '',
          time: '',
          total: 0,//总条数
          pages: 0,//每页数据
          dataList: [],
          rowData: {},
          amountDetail: {//金额明细
            income: "",
            expenditure: "",
            strike: "",
            summary: ""
          },
          details: {
            dialogBudgetDetailsDetails: false,
            params: {
              hsAddCommunity: '',//门店名称
              hsAddDoorplateno: '',//门牌号 / 房号
              hsRoomType: "", //房间类型
              jfAuditState: '',//审核状态
              hsAddDistrict: '', // 城区
              jfStrikeABalanceStatus: '', // 冲账状态
              jfClosedWay: '', // 账户类型
              faId: '', // 账户名称（账户ID）
              jfPayType: '', // 收支方式
              jfTicketNumber: '', // 票据编号
              startTime: '',// 登记时间 - 始
              endTime: '', // 登记时间 - 末
              sort: '2', // 排序
              parameter: '1', // 排序条件
              limit: 10,//每页的条数
              cursor: 1,//初始页
            },
          },
        }
      },
      screenState: false,
      isShowColumn: {//显示/隐藏列
        dialogShowColumn: false,
        checkAll: false,
        checkedCities: ['jfClosedWay','handlersName','jfCheckInTime',],
        cities: [
          {
            label: '账户类型',
            value: 'jfClosedWay',
          },{
            label: '收支方式',
            value: 'jfPayType',
          },{
            label: '收支原因',
            value: 'jfFinanNote',
          },{
            label: '经手人',
            value: 'handlersName',
          },{
            label: '登记日期',
            value: 'jfCheckInTime',
          }
        ],
        isIndeterminate: true,
        showBinding: {
          jfClosedWay: true,//账户类型
          jfPayType: false,//收支方式
          jfFinanNote: false,//收支原因
          handlersName: true,//经手人
          jfCheckInTime: true,//登记日期
        }
      },
      details: {
        dialogDetails: false,
        params: {
          faId: "", //账户ID
          jfAccountingWhy: "", //费用归属地址
          jfTheCashierPeople: "", //登记人（登录人ID）
          jfOperationRecords: "", //操作记录
          jfBelongingToTheName: "", //归属名称
          hrId: "", //未租ID
          hsId: "", //已租ID
          houseCoding: "", //房源ID
          jfManagerUserId: "", //房管员ID
          renterId: "", //租客ID
          laId: "", //业主ID
          jfBillingDate: "", //记账日期
          jfHandlers: "", //经手人ID
          jfTheOwnershipType: "", //归属类型
          jfFinancialCoding: "", //财务流水号
          storefront: "", //店面ID（登录人店面ID）
          department: "", //部门ID（登录人部门ID）
          jfPayType: "", //收支方式
          jfClosedWay: "" //收支账户类型
        },
        dbClickRowData: {}, // 双击行的的数据
        rushAccounts: {
          dialogRushAccounts: false,
          rushAccountsNote: "",// 冲账备注
        },
        examine: {
          auditNote: '', // 审核备注
          auditIsPass: '通过', // 审核是否通过
          dialogExamine: false,
        },
      },
      data: {//数据分页查询
        params: {
          hsAddCommunity: '',//门店名称
          hsAddDoorplateno: '',//门牌号 / 房号
          hsRoomType: "", //房间类型
          jfAuditState: '',//审核状态
          hsAddDistrict: '', // 城区
          jfStrikeABalanceStatus: '', // 冲账状态
          jfClosedWay: '', // 账户类型
          faId: '', // 账户名称（账户ID）
          jfPayType: '', // 收支方式
          jfTicketNumber: '', // 票据编号
          startTime: '',// 登记时间 - 始
          endTime: '', // 登记时间 - 末
          sort: '2', // 排序
          parameter: '1', // 排序条件
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
        time: '',
        sortGp: {//正倒序
          ascBtn: '',
          descBtn: 'primary',
        },
        sort: {//按钮排序
          jfCheckInTime: 'primary',
          jfBillingDate: '',
        },

      },

      dataList: [],
      rowData: {},
      dataCityProper: [],//城区下拉菜单
      dataFloor: [],//楼栋名称下拉菜单
    }
  },
  computed: {
    ...mapState(['setupList','cityList','companyVars','authe'])
  },
  created(){
    this.getDataList()
    //获取账户类型列表
    this.$axios({
      url: "/common1/companyVar/" + 1,
      method: "get"
    }).then( res => {
      this.accountType = JSON.parse(res.data.result.acountType)
    }).catch(err => {
      this.isError(err,this)
    })
    console.log(this.$store.state.companyVars.paymentTypeList)
  },
  mounted(){
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
    })
  },
  methods: {
  //查询数据
    advancedScreening(){//高级筛选
      this.screenState ? this.screenState = false : this.screenState = true
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 130
      })
    },
    getBankAccount(){//根据类型查询银行账户
      this.$axios({
        url: "/common3/bankAccount/list",
        method: "get",
        params: this.data.params.jfClosedWay
      }).then( res => {
        this.data.accountName = res.data.result
        console.log(this.data.accountName)
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getDataList(){//查询数据
      this.dataloading = true
      this.$axios({
        method: 'get',
        url: '/common3/financialRecords',
        params: this.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.dataList = res.data.result.records
          this.data.total = res.data.result.total
        } else {
          this.dataList = []
          this.data.total = 0
        }
        this.dataloading = false
      }).catch(err => {
        this.dataloading = false
        this.isError(err,this)
      })
    },
    tableRowClassName({row, rowIndex}){
      if(row.jfStrikeABalanceStatus === "被冲账" || row.jfStrikeABalanceStatus === "冲账"){
        return "rush-bill-row"
      }
    },
    searchChange(){//筛选条件改变时
      this.data.params.cursor = 1
      this.getDataList()
    },
    dateChange(){//日期改变时查询
      if(this.data.time == null) {
        this.data.params.startTime = ''
        this.data.params.endTime = ''
        this.data.params.cursor = 1
        this.getDataList()
      }else{
        this.data.params.startTime = `${this.data.time[0]} 00:00:00`
        this.data.params.endTime = `${this.data.time[1]} 00:00:00`
        this.data.params.cursor = 1
        this.getDataList()
      }
    },
    sortPopoverBtnGp(key,value){//正倒排序
      for (let i in this.data.sortGp) {
        if (i == key) {
          this.data.sortGp[i] = 'primary'
        }else{
          this.data.sortGp[i] = ''
        }
      }
      this.data.params.sort = value
      this.getDataList()
    },
    sortPopoverBtn(key,value){//排序
      for (let i in this.data.sort) {
        if (i == key) {
          this.data.sort[i] = 'primary'
        }else{
          this.data.sort[i] = ''
        }
      }
      this.data.params.parameter = value
      this.getDataList()
    },
    handleCurrentChange(cursor){//点击更改点前页数
      this.data.params.cursor = cursor;
      this.getDataList()
    },
    handleSizeChange(limit){//点击更改显示条数
      this.data.params.limit = limit;
      this.getDataList()
    },
    currentChange(row){
      this.rowData = row
      console.log(row)
    },
  //收款账户
    getReceivables(){//查询数据
      this.receivables.dialogReceivables = true
      this.$axios({
        method: 'get',
        url: '/common3/bankAccount',
        params: this.receivables.params
      }).then(res => {
        this.receivables.dataList = res.data.result.records
        this.receivables.total = res.data.result.total
        this.receivables.dialogReceivables = true
      }).catch(err => {
        this.isError(err,this)
      })
      this.getReceivablesMoney()
    },
    getReceivablesMoney(num){//查询账户总余额
      this.$nextTick(()=>{
        this.$refs.multipleTable.clearSelection()
      })
      if(num == 1){
        this.$message.success('统计成功')
      }
      this.$axios({
        url: "/common3/bankAccount/getSum",
        method: "get",
        params: {
          faPaymentType: this.receivables.params.faPaymentType,
          faRank: this.receivables.params.faRank,
          faState: this.receivables.params.faState,
        }
      }).then( res => {
        this.receivables.receivablesMoney = res.data.result
      }).catch( err => {
        this.isError(err,this)
      })

    },
    receivablesSearchChange(){//筛选条件改变时
      this.receivables.params.cursor = 1
      this.getReceivables()
    },
    receivablesDateChange(){//日期改变时查询
      if(this.receivables.time == null) {
        this.receivables.params.startTime = ''
        this.receivables.params.endTime = ''
        this.receivables.params.cursor = 1
        this.getReceivables()
      }else{
        this.receivables.params.startTime = `${this.receivables.time[0]} 00:00:00`
        this.receivables.params.endTime = `${this.receivables.time[1]} 00:00:00`
        this.receivables.params.cursor = 1
        this.getReceivables()
      }
    },
    receivablesTableCurrentChange(row){
      this.receivables.rowData = row
    },
    receivablesSizeChange(limit){//点击更改显示条数
      this.receivables.params.limit = limit;
      this.getReceivables()
    },
    receivablesCurrentChange(cursor){//点击更改点前页数
      this.receivables.params.cursor = cursor;
      this.getReceivables()
    },
    SelectionChange(selection){//选择事件
        let results = 0
        if(selection.length == 0){
          this.receivables.receivablesMoney = 0
        }else {
          for(const i of selection){
            results += +i.faTheBalanceOf
          }
          this.receivables.receivablesMoney = results.toFixed(2)
        }
      },
    dialogReceivablesClose(){//收款账户弹窗关闭
      
      this.receivables.params = {
        startTime: "", // 记账日期 - 开始
        endTime: "", // 记账日期 - 结束
        faPaymentType: "", // 账户类型
        faRank: "", // 优先级
        faState: "正常", // 账户状态
        limit: 10,//每页的条数
        cursor: 1//初始页
      }
      this.receivables.time = ""
    },
    //添加
      addAccount(){
        this.receivables.account.title = '添加账户'
        this.receivables.account.state = false
        this.receivables.account.modifyState = false
        this.receivables.account.dialogAccount = true
      },
      addAccountSave(){
        this.$refs.account.validate((valid) => {
          if(valid) {
            this.$axios({
            url: "/common3/bankAccount",
            method: "post",
            data: {
              faAccount: this.receivables.account.params.faAccount,
              faBelonging: this.receivables.account.params.faBelonging,
              faTheInitialAmount: this.receivables.account.params.faTheInitialAmount,
              faTheBalanceOf: this.receivables.account.params.faTheInitialAmount,
              faDescribe: this.receivables.account.params.faDescribe,
              faPaymentType: this.receivables.account.params.faPaymentType,
              faRank: this.receivables.account.params.faRank,
              faUserName: this.receivables.account.params.faUserName
              }
            }).then( res => {
              this.getReceivables()
              this.receivables.account.dialogAccount = false
            }).catch( err => {
              this.isError(err,this)
            })
            }else{
              this.$message({
                showClose: true,
                duration: 6000,
                message: '有必填项为空！',
                type: 'error'
              })
          }
        })
      },
    //修改
      modifyAccount(){
        if(this.receivables.rowData==null||Object.keys(this.receivables.rowData).length == 0){
          return this.$message({
            showClose: true,
            duration: 6000,
            message: "请选择一个账户！",
            type: 'warning'
          })
        }else{
          this.receivables.account.title = '修改账户'
          this.receivables.account.state = true
          this.receivables.account.modifyState = true
          this.receivables.account.dialogAccount = true
          this.receivables.account.params.faAccount =  this.receivables.rowData.faAccount
          this.receivables.account.params.faBelonging =  this.receivables.rowData.faBelonging
          this.receivables.account.params.faTheInitialAmount = this.receivables.rowData.faTheInitialAmount
          this.receivables.account.params.faDescribe =  this.receivables.rowData.faDescribe
          this.receivables.account.params.faPaymentType =  this.receivables.rowData.faPaymentType
          this.receivables.account.params.faRank = this.receivables.rowData.faRank
          this.receivables.account.params.faTheBalanceOf =  this.receivables.rowData.faTheBalanceOf
          this.receivables.account.params.faUserName =  this.receivables.rowData.faUserName
          this.receivables.account.params.faState =  this.receivables.rowData.faState
          this.$nextTick(()=>{
            this.$refs.multipleTable.setCurrentRow(this.receivables.rowData)
          })
        }

      },
      modityState(){//改变期初金额只读状态
        if (this.receivables.account.modifyState) {
          this.receivables.account.modifyState = false
        } else {
          this.receivables.account.modifyState = true
        }
      },
      modifyAccountSave(){
        this.$axios({
          url: "/common3/bankAccount/" + this.receivables.rowData.faId,
          method: "put",
          data: this.receivables.account.params
        }).then( res => {
          this.getReceivables()
          this.receivables.account.dialogAccount = false
        }).catch( err => {
          this.isError(err,this)
        })
      },
      closeDialogAccount(){
        for(const i in this.receivables.account.params){
          this.receivables.account.params[i] = ""
          if(i == "faRank"){
            this.receivables.account.params[i] = 5
          }
          if(i == "faTheInitialAmount"){
            this.receivables.account.params[i] = 0
          }
        }
      },
    //资金调配
      deployment(){
        // console.log(Object.keys(this.receivables.rowData))
        if(this.receivables.rowData==null||Object.keys(this.receivables.rowData).length == 0){
          this.$message({
            showClose: true,
            duration: 6000,
            message: '请选择一个账户进行资金调配！',
            type: 'warning'
          })
        }else {
          this.receivables.deployment.dialogDeployment = true
          this.receivables.deployment.params.faAccount = this.receivables.rowData.faAccount
          this.receivables.deployment.params.faBelonging = this.receivables.rowData.faBelonging
          this.receivables.deployment.params.faPaymentType = this.receivables.rowData.faPaymentType
          this.receivables.deployment.params.faTheBalanceOf = this.receivables.rowData.faTheBalanceOf
          this.receivables.deployment.params.faUserName = this.receivables.rowData.faUserName
          this.receivables.deployment.params = this.receivables.rowData
          this.receivables.deployment.params.moneyChange = "-" + 0
          this.receivables.deployment.params2.moneyChange = "+" + 0
        }
      },
      deploymentSave(){
        let params = this.receivables.deployment.params2
        if(params.faPaymentType == "" || params.faAccount == "" || params.faUserName == "" || params.faBelonging == ""){
          this.$message({
            showClose: true,
            duration: 6000,
            message: '请选择一个转入账户进行资金调配！',
            type: 'error'
          })
        }else if(this.receivables.deployment.moneyChange == "" || this.receivables.deployment.moneyChange == 0){
          this.$message({
            showClose: true,
            duration: 6000,
            message: '请输入调配金额！',
            type: 'error'
          })
        }else if(this.receivables.deployment.params.faTheBalanceOf < this.receivables.deployment.moneyChange){
          this.$message({
            showClose: true,
            duration: 6000,
            message: '调配金额超过转出账户余额！',
            type: 'error'
          })
        }else{
          let records = [
            {
              jfAccountingSpecies: "资金调配",
              jfBigType: "财务类",
              jfNatureOfThe: "支出",
              jfClosedWay: "转账",
              faId: this.receivables.deployment.params.faId,
              // jfHouseId: "1",
              jfTheCashierPeople: sessionStorage.getItem("userCoding"),
              jfBillingDate: this.DateFormat(new Date(),"YYYY-MM-DD"),
              jfHandlers: sessionStorage.getItem("userCoding"),
              jfTheOwnershipType: "非成本项目,",
              jfBelongingToTheName: "帐户资金调配",
              jfAuditState: "未审核",
              jfSumMoney: this.receivables.deployment.moneyChange,
              jfFinanNote:
                "资金调配。从此账户调出" +
                this.receivables.deployment.moneyChange +
                "元到账户：" +
                this.receivables.deployment.params2.faPaymentType +
                this.receivables.deployment.params2.faUserName +
                this.receivables.deployment.params2.faBelonging +
                this.receivables.deployment.params2.faAccount ,
              department: sessionStorage.getItem("departmentId"),
              storefront: sessionStorage.getItem("storefrontId"),
              jfOperationRecords:
                this.DateFormat()+
                sessionStorage.getItem("staffName") +
                "执行帐户资金调配<br>, ",
              jfFinancialCoding:
                this.DateFormat(new Date(),"YYYYMMDDhhmmss") +
                Math.floor(Math.random() * 10) +
                Math.floor(Math.random() * 10) +
                Math.floor(Math.random() * 10) +
                ",",
              jfStartCycle: this.DateFormat(new Date(),"YYYY-MM-DD"),
              jfEndCycle: this.DateFormat(new Date(),"YYYY-MM-DD"),
              number: 0
            },
            {
              jfAccountingSpecies: "资金调配",
              jfBigType: "财务类",
              jfNatureOfThe: "收入",
              jfClosedWay: "转账",
              faId: this.receivables.deployment.params2.faUserName,
              // jfHouseId: "1",
              jfTheCashierPeople: sessionStorage.getItem("userCoding"),
              jfBillingDate: this.DateFormat(new Date(),"YYYY-MM-DD"),
              jfHandlers: sessionStorage.getItem("userCoding"),
              jfTheOwnershipType: "非成本项目",
              jfBelongingToTheName: "帐户资金调配",
              jfAuditState: "未审核",
              jfSumMoney: this.receivables.deployment.moneyChange,
              jfFinanNote:
                "资金调配。从账户：" +
                this.receivables.deployment.params.faPaymentType +
                this.receivables.deployment.params.faUserName +
                this.receivables.deployment.params.faBelonging +
                this.receivables.deployment.params.faAccount +
                "调入" +
                this.receivables.deployment.moneyChange +
                "元到此账户",
              department: sessionStorage.getItem("departmentId"),
              storefront: sessionStorage.getItem("storefrontId"),
              jfOperationRecords:
                this.DateFormat() +
                sessionStorage.getItem("staffName") +
                "执行帐户资金调配<br>, ",
              jfFinancialCoding:
                this.DateFormat(new Date(),"YYYYMMDDhhmmss") +
                Math.floor(Math.random() * 10) +
                Math.floor(Math.random() * 10) +
                Math.floor(Math.random() * 10) +
                ",",
              jfStartCycle: this.DateFormat(new Date(),"YYYY-MM-DD"),
              jfEndCycle: this.DateFormat(new Date(),"YYYY-MM-DD"),
              number: 0
            }
          ];
          this.$axios({
            url: "/common3/financialRecords/createFinancials",
            method: "post",
            data: records
          }).then( res => {
            this.getReceivables()
            this.receivables.deployment.dialogDeployment = false
          }).catch( err => {
            this.isError(err,this)
          })
        }

      },
      getBankAccount2(){//通过账户类型获取
        this.$axios({
          url: "/common3/bankAccount/list",
          method: "get",
          params: {
            faPaymentType: this.receivables.deployment.params2.faPaymentType
          }
        }).then( res => {
          this.receivables.deployment.accountName = res.data.result
          for(const i in this.receivables.deployment.params2){
            if(i == "moneyChange"){
              this.receivables.deployment.params2[i] = "+" + this.receivables.deployment.moneyChange
            }else if( i == "faPaymentType"){
              this.receivables.deployment.params2[i] = this.receivables.deployment.params2[i]
            }else {
              this.receivables.deployment.params2[i] = ""
            }
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getBankAccountInfor(){//获取账户信息
        this.$axios({
          method: 'get',
          url: '/common3/bankAccount/'+ this.receivables.deployment.params2.faUserName,
          // params: this.receivables.budgetDetails.params
          }).then(res => {
            let result = res.data.result
            console.log(result)
            if(result.faId == this.receivables.rowData.faId){
              this.$message({
                showClose: true,
                duration: 6000,
                message: "禁止选择相同账户！",
                type: 'error'
              })
              for(const i in this.receivables.deployment.params2){
                if(i == "moneyChange"){
                  this.receivables.deployment.params2[i] = "+" + this.receivables.deployment.moneyChange
                }else if( i == "faPaymentType"){
                  this.receivables.deployment.params2[i] = this.receivables.deployment.params2[i]
                }else {
                  this.receivables.deployment.params2[i] = ""
                }
              }
            }else{
              this.receivables.deployment.params2.faAccount = result.faAccount
              this.receivables.deployment.params2.faBelonging = result.faBelonging
              // this.receivables.deployment.params2.faPaymentType = result.faPaymentType
              this.receivables.deployment.params2.faTheBalanceOf = result.faTheBalanceOf
              // this.receivables.deployment.params2.faUserName = result.faUserName
            }

          }).catch(err => {
            this.isError(err,this)
          })
      },
      MoneyChange(){
        if((/^0\d*$/g).exec(this.receivables.deployment.moneyChange) ){
          this.receivables.deployment.moneyChange = this.receivables.deployment.moneyChange.replace(/\b(0+)/gi,"")
          this.receivables.deployment.params.moneyChange = "-" + this.receivables.deployment.moneyChange
          this.receivables.deployment.params2.moneyChange = "+" + this.receivables.deployment.moneyChange
        }else {
          this.receivables.deployment.params.moneyChange = "-" + this.receivables.deployment.moneyChange
          this.receivables.deployment.params2.moneyChange = "+" + this.receivables.deployment.moneyChange
        }
      },
      closeDialogDeployment(){
        for(const i in this.receivables.deployment.params2) {
          this.receivables.deployment.params2[i] = ""
        }
        this.receivables.deployment.moneyChange = ""
        this.receivables.deployment.params.moneyChange = ""
      },
    //收支详情
      budgetDetails(row){
        this.load = true
        this.receivables.budgetDetails.dialogBudgetDetails = true
        if(row){
          this.receivables.budgetDetails.params.faId = row.faId
        }
        // this.receivables.budgetDetails.dataList = [{
        //   faId: "",
        //   account: "",
        //   addBuilding: "",
        //   addCity: "深圳市",
        //   addCommunity: "帝王大酒店",
        //   addDistrict: "福田区",
        //   addDoorplateno: "1303",
        //   addProvince: "广东省",
        //   addStreet: "",
        //   addZone: "",
        //   amountOf: "",
        //   authCode: "",
        //   billingDateFrom: "",
        //   billingDateTo: "",
        //   cashierPeopleName: "",
        //   department: "",
        //   ec: "",
        //   endNum: "",
        //   endTime: "",
        //   faBelonging: "",
        //   faUserName: "",
        //   handlersName: "",
        //   historicalReadings: "",
        //   hpIds: "",
        //   hrBase: "",
        //   hrId: "",
        //   hsLeaseState: "",
        //   hsRoomType: "",
        //   isBatchClearCredit: "",
        //   jciId: "",
        //   jcuGroupType: "",
        //   jcuId: "",
        //   jcuType: "",
        //   jfAccountId: 2,
        //   jfAccountingSpecies: "租金",
        //   jfAccountingWhy: "帝王大酒店null1303",
        //   jfAuditState: "未审核",
        //   jfBelongToChannel: "",
        //   jfBelongingToTheName: "",
        //   jfBigType: "主营类",
        //   jfBillingDate: "2019-12-17",
        //   jfCertificateNumber: "",
        //   jfCertificateStatus: "",
        //   jfChargePeople: "",
        //   jfCheckInTime: "2019-12-17 18:39:17",
        //   jfClosedWay: "现金A类",
        //   jfCocId: "",
        //   jfCreditSituation: 0,
        //   jfEndCycle: "2020-01-17",
        //   jfFinanNote: "租客徐展兴在公众号定下帝王大酒店null1303房间",
        //   jfFinanReview: "",
        //   jfFinancialCoding: "20191217183920583",
        //   jfHandlers: "",
        //   jfHaveOrNotArrears: "",
        //   jfHouse4rentId: "",
        //   jfHouse4storeId: 157,
        //   jfHouseId: "",
        //   jfId: 1318,
        //   jfIntendedId: "",
        //   jfJsrcId: "",
        //   jfLandlordId: "",
        //   jfManagerUserId: "",
        //   jfNatureOfThe: "收入",
        //   jfNowBalance: 1776.54,
        //   jfOperationRecords: "(2019-12-17 18:39:20,添加收支记录)*",
        //   jfOrderNum: "",
        //   jfOweMoney: "",
        //   jfPayType: "转账",
        //   jfPricePlan: "",
        //   jfRenterId: "",
        //   jfSettlementMethod: "",
        //   jfStartCycle: "2019-12-17",
        //   jfStrikeABalanceReason: "",
        //   jfStrikeABalanceStatus: "正常",
        //   jfStrikeBalanceEncoding: "",
        //   jfSumMoney: 0.01,
        //   jfTheCashier: "",
        //   jfTheCashierPeople: "",
        //   jfTheOriginalBill: "",
        //   jfTheOwnershipType: "租客",
        //   jfTheReviewer: "",
        //   jfTicketNumber: "",
        //   jmarId: "",
        //   jppId: "",
        //   jppPlanName: "",
        //   jsonArray: "",
        //   keyAdministrator: "",
        //   managerUserName: "",
        //   moneynumber: "",
        //   pageNumber: "",
        //   payType: "",
        //   personChargeAccountName: "",
        //   repId: "",
        //   reviewOneName: "",
        //   reviewPerson: "",
        //   reviewerName: "",
        //   splitFlag: "",
        //   startNum: "",
        //   startTime: "",
        //   storefront: "",
        //   theSortContrary: "",
        //   theSortTerm: "",
        //   total: "",
        //   totalMoney: "",
        //   totalNum: "114",
        //   totalPage: "",
        //   waterElectricalIdentification: "",
        //   wxpayBody: "",
        // }]
        this.$axios({
          method: 'get',
          url: '/common3/financialRecords',
          params: this.receivables.budgetDetails.params
        }).then(res => {
          this.receivables.budgetDetails.dataList = res.data.result.records
          this.receivables.budgetDetails.total = res.data.result.total
          this.load = false
        }).catch(err => {
          this.load = false
          this.isError(err,this)
        })
        this.$axios({
          method: 'get',
          url: '/common3/financialRecords/pageIncome',
          params: {
            faId: this.receivables.budgetDetails.params.faId,
            jfNatureOfThe: this.receivables.budgetDetails.params.jfNatureOfThe,
            startTime: this.receivables.budgetDetails.params.billDataStart,
            endTime: this.receivables.budgetDetails.params.billDataEnd
          }
        }).then(res => {
          console.log(res)
          this.receivables.budgetDetails.amountDetail = res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      budgetDetailsSearchChange(){//筛选条件改变时
        this.receivables.budgetDetails.params.cursor = 1
        this.budgetDetails()
      },
      budgetDetailsTableCurrentChange(row){
        this.receivables.budgetDetails.rowData = row
      },
      budgetDetailsSizeChange(limit){//点击更改显示条数
        this.receivables.budgetDetails.params.limit = limit;
        this.budgetDetails()
      },
      budgetDetailsCurrentChange(cursor){//点击更改点前页数
        this.receivables.budgetDetails.params.cursor = cursor;
        this.budgetDetails()
      },
      budgetDetailsDateChange(){//日期改变时查询
        if(this.receivables.budgetDetails.time == null) {
          this.receivables.budgetDetails.params.billDataStart = ''
          this.receivables.budgetDetails.params.billDataEnd = ''
          this.receivables.budgetDetails.params.cursor = 1
          this.budgetDetails()
        }else{
          this.receivables.budgetDetails.params.billDataStart = `${this.receivables.budgetDetails.time[0]} 00:00:00`
          this.receivables.budgetDetails.params.billDataEnd = `${this.receivables.budgetDetails.time[1]} 00:00:00`
          this.receivables.budgetDetails.params.cursor = 1
          this.budgetDetails()
        }
      },
      budgetDetailsClose(){//收支详情关闭
        this.receivables.budgetDetails.time = []
        this.receivables.budgetDetails.params.billDataStart = ''
        this.receivables.budgetDetails.params.billDataEnd = ''
        this.receivables.budgetDetails.params.jfNatureOfThe = ''
      },
      //收支详细
      budgetDetailsDetails(row){
        this.receivables.budgetDetails.details.dialogBudgetDetailsDetails = true
        this.$axios({
          method: 'get',
          url: '/common3/financialRecords/'+ row.jfId,
          // params: this.receivables.budgetDetails.params
        }).then(res => {
          this.receivables.budgetDetails.details.params = res.data.result.records[0]
        }).catch(err => {
          this.isError(err,this)
        })
      },
  //显示/隐藏列
    userAllChange(value){//租客信息全选
      let arr = []
      for (let i of this.isShowColumn.cities) {
        arr.push(i.value)
      }
      this.isShowColumn.checkedCities = value ? arr : []
      this.isShowColumn.isIndeterminate = false
    },
    userCitiesChange(value){//租客信息改变时
      let checkedCount = value.length
      this.isShowColumn.checkAll = checkedCount === this.isShowColumn.cities.length
      this.isShowColumn.isIndeterminate = checkedCount > 0 && checkedCount < this.isShowColumn.cities.length
      this.isShowColumn.checkedCities = value
    },
    showColumnSave(){//显示/隐藏列保存
      for (let i in this.isShowColumn.showBinding) {
        this.isShowColumn.showBinding[i] = false
      }
      for (let i of this.isShowColumn.checkedCities) {
        for (let k in this.isShowColumn.showBinding) {
          if (i == k) {
            this.isShowColumn.showBinding[k] = true
          }
        }
      }
      this.isShowColumn.dialogShowColumn = false
    },
  //订单详情
    orderDetails(row){//订单详情
      this.details.dbClickRowData = row
      console.log(this.details.dbClickRowData)
      this.$axios({
        method: 'get',
        url: `/common3/financialRecords/${row.jfId}`,
      }).then(res => {
        this.details.params = res.data.result.records[0]
        this.details.dialogDetails = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    viewRoom() {//查看客房
      this.details.dialogDetails = false
      this.$router.push({name:'room',params: {hsId: this.details.params.hsId}})
    },
    rushAccountsSave(){//冲账
      if(this.details.rushAccounts.rushAccountsNote == ""){
        this.$message({
          showClose: true,
          duration: 6000,
          message: '冲账原因必填',
          type: 'error'
        })
      }else {
        this.$axios({
          url: "/common3/financialRecords/strikeBalance",
          method: "put",
          data: {
            jfId: this.details.params.jfId,
            jfOperationRecords:
              this.details.params.jfOperationRecords + ','
              + this.DateFormat()
              + sessionStorage.getItem("staffName")
              + "进行冲账，"
              + "备注：" + this.details.rushAccounts.rushAccountsNote + "<br>",
            jfStrikeABalanceStatus: this.details.params.jfStrikeABalanceStatus,
            jfTheCashierPeople: sessionStorage.getItem("userCoding"),
          }
        }).then( res => {
          this.getDataList()
          this.details.rushAccounts.dialogRushAccounts = false
          this.details.dialogDetails = false

          this.$message({
            showClose: true,
            duration: 6000,
            message: '冲账成功',
            type: 'success'
          })
        }).catch( err => {
          this.isError(err,this)
        })
        this.details.rushAccounts.rushAccountsNote = ""
      }
    },
    examineSave(){//审核
      let jfAuditState = ""
      if(this.details.params.jfAuditState === "未审核"){//审核
        if(this.details.examine.auditIsPass == "通过"){
          jfAuditState = "已审核"
        }else if(this.details.examine.auditIsPass == "不通过" ){
          jfAuditState = "审核不通过"
        }
        console.log(jfAuditState)
        this.$axios({
          url: "/common3/financialRecords/updateReview",
          method: "put",
          data: {
            jfId: this.details.params.jfId,
            jfOperationRecords:
              this.details.params.jfOperationRecords + ','
              + this.DateFormat()
              + sessionStorage.getItem("staffName")
              + "审核" + this.details.examine.auditIsPass
              + "备注：" + this.details.examine.auditNote,
            jfAuditState: jfAuditState,
            jfStrikeABalanceStatus: this.details.params.jfStrikeABalanceStatus,
            jfTheReviewer: sessionStorage.getItem("userCoding"),
          }
        }).then( res => {
          this.getDataList()
          this.details.examine.dialogExamine = false
          this.details.dialogDetails = false
          this.$message({
            showClose: true,
            duration: 6000,
            message: '审核成功！',
            type: 'success'
          })
        }).catch( err => {
          this.isError(err,this)
        })
      }else if(this.details.params.jfAuditState === "已审核") {//复核
        if(this.details.examine.auditIsPass == "通过"){
          jfAuditState = "已复核"
        }else if(this.details.examine.auditIsPass == "不通过" ){
          jfAuditState = "复核不通过"
        }
        this.$axios({
          url: "/common3/financialRecords/updateDealAudit",
          method: "put",
          data: {
            jfId: this.details.params.jfId,
            jfOperationRecords:
              this.details.params.jfOperationRecords + ','
              + this.DateFormat()
              + sessionStorage.getItem("staffName")
              + "复核" + this.details.examine.auditIsPass
              + "备注：" + this.details.examine.auditNote,
            jfAuditState: jfAuditState,
            jfStrikeABalanceStatus: this.details.params.jfStrikeABalanceStatus,
            jfTheReviewer: sessionStorage.getItem("userCoding"),
          }
        }).then( res => {
          this.getDataList()
          this.details.examine.dialogExamine = false
          this.details.dialogDetails = false
          this.$message({
            showClose: true,
            duration: 6000,
            message: '复核成功！',
            type: 'success'
          })
        }).catch( err => {
          this.isError(err,this)
        })
      }
      this.details.examine.auditNote = ""
      this.details.examine.auditIsPass = "通过"
    },
    changeRowData(state){
      let data = this.getIndex(this.dataList,this.rowData,state,this)
      if(!data) {
        return
      }
      this.rowData = data
      this.$refs.highLightRow.setCurrentRow(data);
      this.orderDetails(data)
    },
    followUpCurrentChange(row){//选中行
      this.details.examine.rowData = row
    },
    closeDialogDetails(){
      this.details.params = {
        address: '',//入住客房
        hsRoomType: '',//客房类型
        popName: '',//客户姓名
        jsrcDailyPrice: '',//日均单价
        jsrcTotalDays: '',//总住天数
        jsrcTotalPrice: '',//总住金额
        popTmpList: '',//同住人信息
        jsrcDeposit: '',//客房押金
        jsrcBeginTime: '',//入住时间
        jsrcEndTime: '',//退房时间
        jsrcState: '',//订单状态
        jsrcTypeOccupancy: '',//入住类型
      }
      this.details.rushAccounts.dataConsumption = []
      this.details.rushAccounts.amount = 0
      this.details.examine.dataFollowUp = []
    },
  },
}
</script>
<style lang="scss" scoped>
  .screenInput{
    width: 125px;
  }
  .dialog >>> .el-dialog__body{
    height: 486px;
    overflow: auto;
  }
  span{
    margin: 0;
    padding-left: 10px;
    font-size: 16px;
  }
  .el-table >>> .rush-bill-row{
    background: #DCDCDC
  }
</style>
